// import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

export const LinkBtn = styled(Link)`
  color: currentColor;
  padding: 1rem 2.3rem 0.8rem;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 75rem;
  min-width: 1rem;
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  margin: 1rem;
  transition: all 0.5s ease-in;

  &:hover {
    /* color: var(--primaryRed); */
    background-color: var(--grey-6);
    border-color: var(--black);
  }
`
