import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

import Seo from "../../components/seo"
import Breadcrumb from "../../components/Breadcrumb/index"
import { CategoryMain } from "../../styles/Layout"
// import ShareIcons from "../../utils/ShareIcons"
import {
  FeaturedImage,
  HeroContent,
  LinkList,
} from "../../components/Category/CategoryElements"
import { LinkBtn } from "../../styles/btn"
import {
  QuotesSection,
  QuoteContainer,
  QuoteContent,
  QuoteBlock,
} from "../../components/Quote/QuoteElements"
import Newsletter from "../../components/Newsletter"

const QuoteCategoryPage = ({ data, location }) => {
  const quotes = data.allQuotes.nodes
  const quoteMeta = data.quoteCategory.nodes[0]

  const image = getImage(quoteMeta.featuredImage)
  const featuredImgSrc = getSrc(quoteMeta.featuredImage)

  return (
    <>
      <Seo
        title={quoteMeta.title}
        description={quoteMeta.seoDescription}
        image={featuredImgSrc}
        imageAlt={quoteMeta.featuredImage.description || quoteMeta.title}
        article={false}
      />

      <CategoryMain>
        <FeaturedImage id="main-content">
          <GatsbyImage
            image={image}
            alt={quoteMeta.title}
            style={{
              height: "59vh",
              gridColumn: "1/-1",
              gridRow: "1/-1",
            }}
          />

          <Breadcrumb
            className={null}
            categoryTitle={quoteMeta.title}
            categoryLink={quoteMeta.slug}
          />

          <HeroContent>
            <h1 className="bg-grad">{quoteMeta.title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: quoteMeta.description.childMarkdownRemark.html,
              }}
            />
          </HeroContent>
        </FeaturedImage>

        {quoteMeta.subCategories && (
          <LinkList>
            {quoteMeta.subCategories.map(subCategory => {
              return (
                <LinkBtn
                  key={subCategory.id}
                  to={`/${quoteMeta.slug}/${subCategory.slug}`}
                >
                  {subCategory.title}
                </LinkBtn>
              )
            })}
          </LinkList>
        )}

        <QuotesSection>
          <QuoteContainer>
            {quotes.map(quote => {
              return (
                <QuoteContent id={quote.id} key={quote.id}>
                  <Link
                    to={`/${quote.category.slug}/${quote.subCategory.slug}/${quote.slug}/`}
                  >
                    <QuoteBlock cite={quote.author.name}>
                      <p>{quote.body.body}</p>
                    </QuoteBlock>
                    <figcaption>
                      <div>
                        <p>{quote.title}</p>
                        <span>
                          <time dateTime={new Date(quote.date).toISOString()}>
                            {quote.date}
                          </time>{" "}
                          | {quote.author.name}
                        </span>
                        <br />
                        <span>#Ashalleyistics</span>
                      </div>

                      {/* <ShareIcons
                        url={`${data.site.siteMetadata.siteUrl}/${quote.category.slug}/${quote.subCategory.slug}/${quote.slug}/`}
                      /> */}
                    </figcaption>
                  </Link>
                </QuoteContent>
              )
            })}
          </QuoteContainer>
        </QuotesSection>
        <Newsletter />
      </CategoryMain>
    </>
  )
}

export default QuoteCategoryPage

export const QuoteQuery = graphql`
  query Quotes {
    site {
      siteMetadata {
        siteUrl
      }
    }

    allQuotes: allContentfulQuote {
      nodes {
        id: contentful_id
        title
        slug
        date(formatString: "MMM D, YYYY")
        category {
          slug
        }
        subCategory {
          title
          slug
        }
        body {
          body
        }
        author {
          name
        }
      }
    }

    quoteCategory: allContentfulCategory(
      filter: { slug: { eq: "inspirational-quotes" } }
    ) {
      nodes {
        title
        slug
        seoDescription
        description {
          childMarkdownRemark {
            html
          }
        }
        featuredImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
            aspectRatio: 1.777777
            quality: 80
          )
          description
        }
        subCategories {
          title
          slug
          id: contentful_id
        }
      }
    }
  }
`
